<template>
  <Content>
    <template #contentTitle>
      {{ $t("menu.storage_management") }}
    </template>
    <template v-slot:contentBody="wrap">
      <div>
        <a-row :gutter="[16, 16]" type="flex" justify="space-between" align="bottom">
          <a-col flex="1">
            <a-row :gutter="[16, 16]">
              <a-col>
                <a-select
                  v-model:value="selectedWarehouse"
                  :allowClear="true"
                  optionFilterProp="search-key"
                  show-search
                  :placeholder="$t('warehouse.warehouse_no')"
                  style="width: 250px;"
                >
                  <a-select-option
                    v-for="item in inventoryWarehouses"
                    :key="item.warehouseId"
                    :title="`${item.warehouseNo}(${item.warehouseName})`"
                    :search-key="item.warehouseNo + item.warehouseName"
                    :value="item.warehouseId"
                  >
                    {{ item.warehouseNo }}({{ item.warehouseName }})
                  </a-select-option>
                </a-select>
              </a-col>
              <a-col>
                <a-select
                  v-model:value="selectedStatus"
                  :allowClear="true"
                  :placeholder="$t('logistics.status')"
                  style="width: 250px"
                >
                  <a-select-option
                    v-for="(value, key) in boxStatus"
                    :key="key"
                    :title="$t($enumLangkey('boxStatus', value))"
                    :value="value"
                  >
                    {{ $t($enumLangkey("boxStatus", value)) }}
                  </a-select-option>
                </a-select>
              </a-col>
              <a-col>
                <SearchProduct ref="refSearchProduct"></SearchProduct>
              </a-col>
              <a-col>
                <a-input-group compact style="width: 300px;">
                  <a-select
                    v-model:value="selectedSearchType"
                    style="width: 40%;"
                  >
                    <a-select-option
                      v-for="item in searchType"
                      :key="item.value"
                      :title="$t(item.text)"
                      :value="item.value"
                    >
                      {{ $t(item.text) }}
                    </a-select-option>
                  </a-select>
                  <a-input style="width: 60%;" v-model:value="searchKey" />
                </a-input-group>
              </a-col>
              <a-col>
                <a-button
                  type="primary"
                  :loading="productListLoading"
                  @click="handleSearch"
                  >{{ $t("common.query") }}</a-button
                >
              </a-col>
              <a-col>
                <a-button
                  type="ghost"
                  :loading="productListLoading"
                  @click="handleDownloadExcel"
                  >{{ $t("common.export") }}</a-button
                >
              </a-col>
            </a-row>
          </a-col>
          <a-col>
            <a-button
              type="link"
              :loading="productListLoading"
              @click="handleExportProductWarehouseTime"
              >{{ $t("common.export_product_in_the_warehouse_time") }}</a-button
            >
          </a-col>
        </a-row>
      </div>
      <div class="inventory-table mt-3">
        <a-table
          :columns="columns"
          :data-source="tableList"
          :scroll="{ x: 500, y: wrap.contentHeight - 120 }"
          :pagination="false"
          size="small"
          :loading="productListLoading"
        >
          <template #nameAndNo>
            <div>
              {{ $t("warehouse.product_name") }}
            </div>
            <div>
              {{ $t("common.product_no") }}
            </div>
          </template>
          <template #encasementSize>
            <div>
              {{ $t("warehouse.encasement_specification") }}
            </div>
            <div>
              {{ $t("warehouse.in_warehouse_size") }}
            </div>
          </template>
          <template #encasementWeight>
            <div>
              {{ $t("warehouse.encasement_weight") }}
            </div>
            <div>
              {{ $t("warehouse.in_warehouse_weight") }}
            </div>
          </template>

          <template #statusCustom="{ record }">
            {{ $t($enumLangkey("boxStatus", record.status)) }}
          </template>

          <template #inWarehousePlanNo="{ record }">
            <CPlanNumberItem :no="record.inWarehousePlanNo"></CPlanNumberItem>
          </template>

          <template #outWarehousePlanNo="{ record }">
            <CPlanNumberItem :no="record.outWarehousePlanNo"></CPlanNumberItem>
          </template>

          <template #inWarehouseTimeCustom="{ record }">
            {{ $filters.utcToCurrentTime(record.inWarehouseTime) }}
          </template>

          <template #outWarehouseTimeCustom="{ record }">
            {{ $filters.utcToCurrentTime(record.outWarehouseTime) }}
          </template>

          <template #related_products="{ record }">
            <CHaveProductInformation
              :disabled="record.productKindCount < 1"
              @click="(e) => handleClickProductIoc(record)"
            />
          </template>

          <template #encasementSizeCustom="{ record }">
            <div>
              {{ record.encasementSize }}
            </div>
            <div>
              {{ record.inWarehouseSize }}
            </div>
          </template>

          <template #encasementWeightCustom="{ record }">
            <div>
              {{ record.encasementWeight }}
            </div>
            <div>
              {{ record.inWarehouseWeight }}
            </div>
          </template>

          <template #operate="{ record }">
            <a-dropdown>
              <template #overlay>
                <a-menu @click="handleLookPlan">
                  <a-menu-item key="inPlan" :planId="record">
                    {{ $t("warehouse.check_in_warehouse") }}
                  </a-menu-item>
                  <a-menu-item
                    key="outPlan"
                    v-if="
                      record.status == boxStatus.awaitOutWarehouse ||
                      record.status == boxStatus.awaitDeliveredOrHasBeenOut ||
                      record.status == boxStatus.delivered
                    "
                    :planId="record"
                  >
                    {{ $t("warehouse.check_out_warehouse") }}
                  </a-menu-item>
                  <a-menu-item
                    key="anew"
                    v-if="record.isCanUpdateProduct"
                    :record="record"
                  >
                    {{ $t("warehouse.alter_product_info") }}
                  </a-menu-item>
                </a-menu>
              </template>
              <a-button>
                {{ $t("logistics.look") }}
                <DownOutlined />
              </a-button>
            </a-dropdown>
          </template>

          <template #boxNoCustom="{ record }">
            <a-row :gutter="[0, 0]" class="ml-1">
              <a-col>
                {{ record.boxNo }}
                <div class="mt-1">
                  <UserDefinedBoxNo
                    :boxId="record.whBoxId"
                    v-model:userDefinedBoxNo="record.selfBoxNo"
                  ></UserDefinedBoxNo>
                </div>
              </a-col>
              <a-col>
                <CTagMixedOrSingle
                  :productKindCount="record.productKindCount * 1"
                ></CTagMixedOrSingle>
              </a-col>
            </a-row>
          </template>
        </a-table>
      </div>

      <!-- 修改产品数据 -->
      <a-modal
        width="800px"
        v-model:visible="updateProductModal.visible"
        :centered="true"
        :maskClosable="false"
        :title="
          $t('warehouse.alter_product_info') +
          '(' +
          updateProductModal.record?.boxNo +
          ')'
        "
        :footer="null"
      >
        <a-form layout="inline">
          <a-form-item class="mb-1">
            <SearchProduct
              ref="refSearchProduct1"
              v-model:productData="updateProductModal.productData"
            ></SearchProduct>
          </a-form-item>
          <a-form-item class="mb-1">
            <a-button
              type="primary"
              :disabled="!updateProductModal.productData"
              ghost
              @click="handleAdd"
              >{{ $t("common.add") }}</a-button
            >
          </a-form-item>
        </a-form>
        <a-table
          :columns="updateProductModalColumns"
          :data-source="updateProductModal.list"
          :scroll="{ x: 500, y: 600 }"
          :loading="updateProductModal.loading"
          size="small"
          :rowKey="
            (record, index) => {
              return index;
            }
          "
          :pagination="false"
        >
          <template #product_info="{ record }">
            <a-space>
              <div class="table-list-img-common">
                <c-image :src="record?.productImgUrl" />
              </div>
              <div>
                <div>{{ record?.productName }}</div>
                <div>{{ record?.productNo }}</div>
              </div>
            </a-space>
          </template>

           <template #seSku="{ record }">
              <div>{{ record?.seSku }}</div>
          </template>

          <template #number="{ record }">
            <a-input-number
              v-model:value="record.containerCount"
              :precision="0"
              :min="1"
            ></a-input-number>
            Unit
          </template>

          <template #remove="{ record }">
            <div class="ant-form-inline">
              <a-button @click="handleUpdateProductModalRemove(record)">{{
                $t("common.remove")
              }}</a-button>
            </div>
          </template>
        </a-table>

        <div class="mt-2">
          <span class="text-error">{{ $t("warehouse.warn") }}:</span>
          <div class="text-error">
            {{ $t("warehouse.modify_box_product_data_warning") }}
          </div>
        </div>

        <a-row
          type="flex"
          justify="center"
          align="middle"
          class="mt-3"
          :gutter="[48, 12]"
        >
          <a-col>
            <a-button
              :loading="updateProductModal.loading"
              @click="updateProductModal.visible = false"
              >{{ $t("common.cancel") }}</a-button
            >
          </a-col>
          <a-col>
            <a-button
              :disabled="!updateProductModal.list||updateProductModal.list?.length == 0"
              :loading="updateProductModal.loading"
              type="primary"
              @click="handleConfirmUpdateProductModal"
              >{{ $t("common.save") }}</a-button
            >
          </a-col>
        </a-row>
      </a-modal>

      <div 
        v-if="productDetail.whboxId">
        <CProductDetailModal
          v-model:visible="productDetail.visible"
          :whboxId="productDetail.whboxId"
          :boxNo="productDetail.boxNo"
        ></CProductDetailModal>
      </div>
    </template>
    <template #contentFooter>
      <a-row type="flex" justify="space-around" align="middle">
        <a-col>
          <CPager @handlePage="handlePage" :page-data="pageData"></CPager>
        </a-col>
      </a-row>
    </template>
  </Content>
</template>

<script>
import { useI18n } from "vue-i18n/index";
import { reactive, toRefs, onMounted, onActivated, ref,nextTick } from "vue";
import {
  Row,
  Col,
  Dropdown,
  Menu,
  Modal,
  Tag,
  Table,
  Input,
  AutoComplete,
  Card,
  InputNumber,
  Select,
  Button,
  Form,
  Space,
  message,
} from "ant-design-vue";
import Content from "../components/Content.vue";
import CPager from "../components/CPager.vue";
import {
  getInventoryWarehouses,
  getInventoryList,
  updateProductInfo,
  exportInventoryList,
  exportInventoryListWithStockAge,
} from "../../api/modules/storage/index";
import { boxStatus } from "../../enum/enum.json";
import CImage from "../components/CImage.vue";
import SearchProduct from "../components/SearchProduct.vue";
import { useRouter } from "vue-router";
import { gToKg, dateString } from "../../utils/general";
import UserDefinedBoxNo from "../components/UserDefinedBoxNo.vue";
import CPlanNumberItem from "../components/CPlanNumberItem.vue";
import { downloadFile } from "@/utils/downloader.js";
import CTagMixedOrSingle from "../components/CTagMixedOrSingle.vue";
import CHaveProductInformation from "../components/CHaveProductInformation.vue";
import CProductDetailModal from "../components/CProductDetailModal.vue";
import { getBoxOfProductsByWhboxId } from "../../api/modules/common/index";

export default {
  name: "storage_inventory",
  components: {
    CProductDetailModal,
    CHaveProductInformation,
    CTagMixedOrSingle,
    SearchProduct,
    UserDefinedBoxNo,
    CPlanNumberItem,
    CImage,
    Content,
    CPager,
    ARow: Row,
    ACol: Col,
    ADropdown: Dropdown,
    AMenu: Menu,
    AMenuItem: Menu.Item,
    AAutoComplete: AutoComplete,
    ATable: Table,
    AInput: Input,
    AInputGroup: Input.Group,
    AInputNumber: InputNumber,
    ASelect: Select,
    ASelectOption: Select.Option,
    AButton: Button,
    AFormItem: Form.Item,
    AForm: Form,
    ASpace: Space,
    AModal: Modal,
    ACard: Card,
    ATag: Tag,
  },
  setup() {
    const refPage = ref();
    const refSearchProduct = ref();
    const refSearchProduct1 = ref();
    const router = useRouter();
    const vueI18n = useI18n({ useScope: "global" });

    //#region columns
    const columns = [
      {
        fixed: "left",
        width: 200,
        dataIndex: "boxNo",
        slots: {
          customRender: "boxNoCustom",
        },
        title: () => vueI18n.t("warehouse.box_no"),
      },
      {
        dataIndex: "warehouseNo",
        title: () => vueI18n.t("warehouse.warehouse_no"),
        width: 110,
      },
        {
        title: () => vueI18n.t("common.related_products"),
        slots: {
          customRender: "related_products",
        },
        width: 100,
      },
      {
        dataIndex: "encasementSize",
        slots: {
          title: "encasementSize",
          customRender: "encasementSizeCustom",
        },
        width: 120,
      },
      {
        dataIndex: "containerCount",
        title: () => vueI18n.t("warehouse.encasement_count"),
        width: 90,
      },
      {
        dataIndex: "encasementWeight",
        slots: {
          title: "encasementWeight",
          customRender: "encasementWeightCustom",
        },
        width: 100,
      },
      {
        dataIndex: "status",
        title: () => vueI18n.t("warehouse.status"),
        slots: {
          customRender: "statusCustom",
        },
        width: 120,
      },
      {
        dataIndex: "inWarehousePlanNo",
        title: () => vueI18n.t("warehouse.in_warehouse_no"),
        slots: {
          customRender: "inWarehousePlanNo",
        },
        width: 150,
      },
      {
        dataIndex: "inWarehouseTime",
        title: () => vueI18n.t("warehouse.in_warehouse_time"),
        slots: {
          customRender: "inWarehouseTimeCustom",
        },
        width: 150,
      },
      {
        dataIndex: "outWarehousePlanNo",
        title: () => vueI18n.t("warehouse.out_warehouse_plan_no"),
        slots: {
          customRender: "outWarehousePlanNo",
        },
        width: 150,
      },
      {
        dataIndex: "outWarehouseTime",
        title: () => vueI18n.t("warehouse.out_warehouse_time"),
        slots: {
          customRender: "outWarehouseTimeCustom",
        },
        width: 150,
      },
    
      {
        dataIndex: "operate",
        fixed: "right",
        slots: {
          customRender: "operate",
          title: "",
        },
        width: 100,
      },
    ];
    //#endregion

    const state = reactive({
      inventoryWarehouses: [],
      boxStatus: boxStatus,
      searchType: [
        { text: "warehouse.box_no", value: "boxNo" },
        { text: "warehouse.in_warehouse_plan_no", value: "inWarehousePlanNo" },
        {
          text: "warehouse.out_warehouse_plan_no",
          value: "outWarehousePlanNo",
        },
        { text: "common.user_defined_box_no", value: "selfBoxNo" },
      ],
      productListLoading: false,
    });

    const data = reactive({
      selectedSearchType: "boxNo",
      searchKey: "",
      selectedWarehouse: null,
      selectedStatus: null,

      tableList: [],
      outWarehousePlanNos: [],
      pageData: {
        currentIndex: 1,
        skipCount: 0,
        totalCount: 0,
        maxResultCount: 10,
      },
      productDetail:{
        visible: false,
        whboxId: null,
        boxNo: null,
      }
    });

    const handlePage = async (pageData) => {
      data.pageData.skipCount = pageData.skipCount;
      data.pageData.maxResultCount = pageData.maxResultCount;
      funcSearch();
    };

    const handleSearch = async () => {
      data.pageData.skipCount = 0;
      data.pageData.currentIndex = 1;
      funcSearch();
    };

    const funcSearch = () => {
      let searchData = {
        productId: refSearchProduct.value.selectedProductId,
        warehouseId: data.selectedWarehouse,
        containerBoxStatus: data.selectedStatus,
        [data.selectedSearchType]: data.searchKey,
        ...data.pageData,
      };

      state.productListLoading = true;
      getInventoryList(searchData)
        .then((res) => {
          if (res.result) {
            let { items, totalCount } = res.result;
            data.tableList = items.map((x) => {
              return {
                key: x.id,
                boxNo: x.boxNo,
                selfBoxNo: x.selfBoxNo,
                warehouseNo: x.warehouseNo,
                imgUrl: x.productImgUrl,
                encasementSize:
                  x.containerLength && x.containerWidth && x.containerHeight
                    ? x.containerLength +
                      "x" +
                      x.containerWidth +
                      "x" +
                      x.containerHeight +
                      " cm"
                    : "-",
                inWarehouseSize:
                  x.checkContainerLength &&
                  x.checkContainerLength &&
                  x.checkContainerWidth
                    ? x.checkContainerLength +
                      "x" +
                      x.checkContainerWidth +
                      "x" +
                      x.checkContainerHeight +
                      " cm"
                    : "-",
                containerCount: x.containerCount,
                encasementWeight: x.containerWeight
                  ? gToKg(x.containerWeight) + " kg"
                  : "-",
                inWarehouseWeight: x.checkContainerWeight
                  ? gToKg(x.checkContainerWeight) + " kg"
                  : "-",
                status: x.containerBoxStatus,
                inWarehousePlanId: x.inWarehousePlanId,
                inWarehousePlanNo: x.inWarehousePlanNo || "-",
                inWarehouseTime: x.inWarehouseTime,
                outWarehousePlanId: x.outWarehousePlanId,
                outWarehousePlanNo: x.outWarehousePlanNo || "-",
                outWarehouseTime: x.lastModificationTime,
                selfBoxNo: x.selfBoxNo,
                productKindCount: x.productKindCount,
                whBoxId: x.id,
                warehouseId: x.warehouseId,
                productRls: x.productRls,
                isCanUpdateProduct: x.isCanUpdateProduct,
                transportCharacteristics: x.transportCharacteristics,
              };
            });
            data.pageData.totalCount = parseInt(totalCount);
          } else {
            data.tableList = [];
            data.pageData.totalCount = 0;
          }
        })
        .finally(() => {
          state.productListLoading = false;
        });
    };

    const funcUserOfInventoryWarehouses = async () => {
      let res = await getInventoryWarehouses({});
      state.inventoryWarehouses = res.result;
    };

    const handleLookPlan = async (option) => {
      if (option.key === "inPlan") {
        router.push({
          name:
            "storage_inbound_detail",
             params: { id: option.item.planId.inWarehousePlanId } 
        });
      } else if (option.key === "outPlan") {
        router.push({
          path:
            "/storage/outplandetails/" + option.item.planId.outWarehousePlanId,
        });
      } else {
        handleShowUpdateProductModal(option.item.record);
      }
    };

    const handleDownloadExcel = () => {
      state.productListLoading = true;
      let url = exportInventoryList();
      const postData = {
        productId: refSearchProduct.value.selectedProductId,
        warehouseId: data.selectedWarehouse,
        containerBoxStatus: data.selectedStatus,
        [data.selectedSearchType]: data.searchKey,
      };
      downloadFile(
        url,
        `storage_inventory_${dateString()}.csv`,
        "POST",
        postData
      )
        .then(() => {
          state.productListLoading = false;
        })
        .catch(() => {
          state.productListLoading = false;
        });
    };

    const handleExportProductWarehouseTime = () => {
      state.productListLoading = true;

      let url = exportInventoryListWithStockAge();
      const postData = {
        productId: refSearchProduct.value.selectedProductId,
        warehouseId: data.selectedWarehouse,
        containerBoxStatus: data.selectedStatus,
        [data.selectedSearchType]: data.searchKey,
      };
      downloadFile(
        url,
        `storage_inventory_age_${dateString()}.csv`,
        "POST",
        postData
      )
        .then(() => {
          state.productListLoading = false;
        })
        .catch(() => {
          state.productListLoading = false;
        });
    };

    const handleClickProductIoc = (record) => {
      data.productDetail.boxNo = record.boxNo;
      data.productDetail.whboxId = record.key;
      data.productDetail.visible = true;
    };

    //--------------------------修改产品数据 start---------------------------------------------------
    const updateProductModalColumns = [
      {
        title: () => vueI18n.t("warehouse.product_info"),
        slots: {
          customRender: "product_info",
        },
        width: 150,
      },
      {
        title: 'SESKU',
        slots: {
          customRender: "seSku",
        },
        width: 150,
      },

      {
        title: () => vueI18n.t("common.count"),
        slots: {
          customRender: "number",
        },
        width: 120,
      },

      {
        title: "",
        slots: {
          customRender: "remove",
        },
        fixed: "right",
        width: 80,
      },
    ];

    const updateProductModal = reactive({
      visible: false,
      loading: false,
      record: null,
      productData: null,
      list: [],
    });

    const handleShowUpdateProductModal = (record) => {
      updateProductModal.visible = true;
      updateProductModal.record = record;
      
      updateProductModal.productData = null;
      nextTick(()=>{
        refSearchProduct1.value.searchProductKey = null;
        refSearchProduct1.value.oldSearchKey = null;
      })
      
      updateProductModal.loading = true;
      getBoxOfProductsByWhboxId(record.key)
        .then(({ result }) => {
          updateProductModal.list = result;
          updateProductModal.loading = false;
        })
        .catch(() => {
          updateProductModal.loading = false;
        });
    };

    const handleAdd = () => {
      if (
        updateProductModal.list?.findIndex(
          x => x.productId == updateProductModal.productData.id
          ||
          x.id == updateProductModal.productData.id
          ) >= 0 
      ) {
        return message.error(vueI18n.t('warehouse.product_already_exists'))
      }
      updateProductModal.productData.productImgUrl=updateProductModal.productData.imgUrl;
      updateProductModal.productData.containerCount=null;
      updateProductModal.list?.push(updateProductModal.productData);
      updateProductModal.productData = null;
      refSearchProduct1.value.searchProductKey = null;
      refSearchProduct1.value.oldSearchKey = null;
    };

    const handleUpdateProductModalRemove = (record) => {
      updateProductModal.list = updateProductModal.list.filter(
        (x) => x.productId != record.productId
      );
    };

    const handleConfirmUpdateProductModal = () => {
      // if (updateProductModal.list?.length == 0) {
      //   return message.error(
      //     vueI18n.t("common.p0_is_required", [vueI18n.t("warehouse.product")])
      //   );
      // }

      if (updateProductModal.list.find((x) => !x.containerCount)) {
        return message.error(
          vueI18n.t("common.p0_is_required", [vueI18n.t("common.count")])
        );
      }

      updateProductModal.loading = true;
      let items = updateProductModal.list?.map((x) => {
        return {
          productId: x.id || x.productId,
          containerCount: x.containerCount,
        };
      });
      updateProductInfo(
        updateProductModal.record.whBoxId,
        updateProductModal.record.warehouseId,
        items
      )
        .then(({ result }) => {
          updateProductModal.visible = false;
          updateProductModal.record.productRls = updateProductModal.list;
          updateProductModal.loading = false;
          funcSearch();
        })
        .catch(() => {
          updateProductModal.loading = false;
        });
    };

    //--------------------------修改产品数据 end---------------------------------------------------

    onMounted(async () => {
      funcUserOfInventoryWarehouses();
    });

    onActivated(async () => {
      funcSearch();
    });

    return {
      columns,
      refPage,
      refSearchProduct,
      refSearchProduct1,
      ...toRefs(state),
      ...toRefs(data),
      handlePage,
      funcSearch,
      handleSearch,
      handleLookPlan,
      handleDownloadExcel,
      handleExportProductWarehouseTime,
      handleClickProductIoc,

      updateProductModalColumns,
      updateProductModal,
      handleShowUpdateProductModal,
      handleAdd,
      handleUpdateProductModalRemove,
      handleConfirmUpdateProductModal,
    };
  },
};
</script>

<style lang="less" scoped></style>
